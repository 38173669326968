<template>
	<div v-if="!loading" class="space-authors content-padding">
        <div class="row">
            <div class="col-12">
                <div class="space-input" v-for="(input, inputName) in inputs" :key="`space-${inputName}`">
                    <BaseCard v-if="input.kind == 'list'" :padding="'0 25px 25px'">
                        <SimpleList
                            :label="input.label"
                            :current-value="input.value || []"
                            :structure="input.structure"
                        />
                    </BaseCard>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { debounce } from "@/services/debounce";

    export default {
        name: 'SpaceAuthors',
        components: {
            BaseCard: () => import("@/components/Cards/BaseCard"),
            SimpleList: () => import("@/components/List/SimpleList")
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            localData: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            spaceFields: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters('helper', ['labels']),
            inputs() {
                let result = {};
                const inputsToShow = ['authors'];

                inputsToShow.forEach(inputName => {
                    if (this.spaceFields[inputName]) result[inputName] = {
                        ...this.spaceFields[inputName],
                        value: this.localData[inputName]
                    }
                });

                return result;
            }
        },
        methods: {
            dataUpdated(name, value) {
                debounce(() => {
                    this.$emit('data-updated', name, value);
                }, 200, `space-input-${name}`);
            },
            fileUpdated(name, value) {
                debounce(() => {
                    if (value.files?.[0]?.file) this.$emit('data-updated', name, value.files[0].file);
                }, 200, `space-input-${name}`);
            }
        },
        mounted() {
            
        },
        beforeRouteLeave (to, from, next) {
            next();
        },
    }
</script>

<style lang='scss' scoped>
@mixin input-title {
    font-family: "Oswald", sans-serif;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #e7e7e7;
    margin: 0 -25px 25px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 400;
}

.space {
    &-input {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0px;
        }

        .image-preview {
            max-width: 100%;
            max-height: 200px;
            height: auto;
            object-fit: contain;
            object-position: left;
        }
        ::v-deep {
            .simple-list {
                h6 {
                    @include input-title;
                }
            }

            .base-card > .formulate-input {
                .formulate-input-wrapper {
                    label {
                        @include input-title;
                    }

                    .formulate-input-element {
                        max-width: unset;
                        width: 100%;
                    }

                    input,
                    textarea,
                    .editor {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>